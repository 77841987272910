
<template>
    <div class="h-100 mt-4" style="overflow-y: auto;">
        <div class="container mb-5 pb-5 " style="width:100%">
            <div class=" bg-white rounded p-3" v-if="isOpen">
                <b-modal :title="'Czy napewno usunąć ' + (deleteModal.type == 'file' ? 'plik?' : 'folder?')"
                    v-model="deleteModal.modal" hide-footer>
                    <div class="p-4">
                        <div>{{ deleteModal.type == 'file' ? 'Plik' : 'Folder' }} zostanie bezpowrotnie usuniety. Czy
                            chcesz
                            go
                            usunąć?
                        </div>
                        <div class="text-center mt-5">
                            <button class="btn btn-outline-secondary me-2"
                                @click.prevent="deleteModal.modal = false">Anuluj</button>
                            <button class="btn btn-danger" @click.prevent="permitDelete">Usuń</button>
                        </div>
                    </div>
                </b-modal>

                <div class="row p-0 m-0 mt-2">
                    <div class="col-md-3" v-if="!hidefolders || hidefolders === null">
                        <button style="cursor:pointer" class="btn btn-outline-secondary mb-2"
                            @click.prevent="isFolderBox = !isFolderBox">Stwórz nowy folder</button>
                        <div v-if="isFolderBox" class="pb-4 mb-4 border-bottom">
                            <div>
                                <div class="md-title">
                                    <p class="lead">Utwórz nowy folder na serwerze</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label class="col-form-label w-100">Wprowadź nazwę folderu</label>
                                    <input class="form-control" v-model="new_folder_name" />
                                </div>
                            </div>
                            <div class="left mt-2">
                                <button class=" btn  btn-outline-primary me-2" :disabledx="isFolderBox"
                                    @click.prevent="createFolder">Utwórz folder</button>
                                <button class="md-raised btn btn-outline-secondary"
                                    @click.prevent="isFolderBox = false">Anuluj</button>
                            </div>
                        </div>
                        <div :show="loadingCustomPath">

                            <ul id="demo" style="list-style:none;" class="ps-1 mt-3">
                                <FolderTree :remote="server.type != 'local'" v-for="server, si in servers" :key="si"
                                    @delete="deleteFolder($event)"
                                    @activate="selectedServer = server.name; getFolderList(); reloadFolder('/');"
                                    :label="server.label" :active="server.name == selectedServer && !loadingFolder"
                                    :edit="edit" class="item" :item="treeData" @add-item="addItem" :create="isFolderBox">
                                </FolderTree>

                            </ul>
                        </div>

                    <!-- <div class="form-group">
                            <label>Serwer</label>

                            <div class="nav flex-column nav-pills me-3"
                                aria-orientation="vertical">
                                <button  v-for="server, si in servers"  :key="si" class="nav-link" @click="selectedServer=server.name;getFolderList(); files = []" :class="server.name==selectedServer?'bg-primary text-white':''" >{{ server.label }}</button>
                                
                            </div>
                                                                    </div> -->
                    </div>
                    <div class="col-md-9" :class="{ 'col-md-9': !hidefolders, 'col-md-12': hidefolders }">
                        <div class="mb-2">
                            <span class="md-title">
                                <img v-show="false" v-if="filtr" :src="baseUrl + 'assets/images/media/' + filtr + '.png'"
                                    alt="Wyniki są przefiltrowane przez rodzaj pliku"
                                    title="Wyniki są przefiltrowane przez rodzaj pliku">
                            </span>
                            <div v-if="acl.edit" class="text-center mb-2">
                                Dodajesz pliki do folderu: <span style="font-size:14px; font-weight:700; color:black">
                                    {{ folder_path.replace('/', '/').replace('files', 'Katalog główny') }}</span> </div>
                        <!-- <div class="text-center p-4" style="background-color: #f4f7fa;">
                <fileupload  class="btn-input btn-has-file" target="<?=base_url();?>panel/api/files/upload/" action="POST" v-on:start="startUpload" v-on:progress="progress" v-on:finish="finishUpload"></fileupload>
                                                        </div>-->
                            <vue-dropzone v-if="dropzoneOptions.url" v-on:vdropzone-success="uploadSuccess"
                                v-on:vdropzone-error="uploadFail" v-on:vdropzone-queue-complete="uploadSuccessAll"
                                v-on:vdropzone-sending="sendingEvent" ref="myVueDropzone" id="dropzone"
                                :options="dropzoneOptions" :use-custom-slot="true">
                                <div class="dropzone-custom-content">
                                    <h5 class="dropzone-custom-title">Upuść plik tutaj</h5>
                                    <h6 class="subtitle">...lub kliknij i wybierz plik ze swojego komputera</h6>
                                </div>
                            </vue-dropzone>
                        </div>
                        <div class="form-group">
                            <div class="d-flex flex-wrap align-items-center mb-3">
                                <div class="col-12 col-lg-4  mt-1">
                                    Jesteś tutaj: {{ getServerLabel() }}/{{ folder_path.replace('files/', '') }}
                                </div>
                                <div class="col-12 col-lg-8 ">
                                    <div class="input-group ms-11">
                                        <input placeholder="Wyszukaj plik po nazwie" tabidex="0" class="form-control"
                                            type="text" @input="reloadFolder(folder_path)" v-model="search_name">
                                        <button @click.prevent="search_name = ''; reloadFolder(folder_path)"
                                            class="btn btn-outline-secondary form-input-append">Wyczyść</button>
                                    </div>
                                </div>
                            </div>
                            <DIV :show="loading" rounded="sm">
                                <i v-show="files.length == 0">Nie znaleziono plików</i>
                                <div style="display:flex; flex-direction: row; flex-wrap:wrap;">
                                    <div class="image-caption  col-lg-5ths col-md-4 col-6 px-0" :key="fi"
                                        v-for="file, fi in files" style="cursor: pointer; position:relative;"
                                        @click="clickImage(file)">
                                        <div class="border me-1 ms-11 mb-2" style="height: 200px;">
                                            <div class="m-2 text-center">
                                                <div class="choose_icon ">
                                                    <span v-if="!edit"
                                                        style="position: absolute; font-size:60px; top:40%;right:35%; color:#fff"
                                                        class="material-icons-outlined ">task_alt</span>
                                                </div>
                                                <div class="buttons_edit text-center" v-if="edit">
                                                    <a v-tooltip href="#" @click.prevent="copyToClipboard(file.publicUrl)"
                                                        title="Skopiuj link" class="me-2 btn btn-icon-sm p-2 btn-primary">
                                                        <i class="material-icons-outlined">link</i>
                                                    </a>
                                                    <a v-tooltip target="_blank" :href="file.publicUrl" title="Podgląd"
                                                        class=" me-2 btn btn-icon-sm p-2 btn-primary">
                                                        <i class="material-icons-outlined">search</i>
                                                    </a>
                                                    <a v-tooltip v-if="true" @click.prevent="deleteFile(file)" href="#"
                                                        title="Usuń"
                                                        class=" me-1 delete_file_link btn btn-icon-sm p-2 btn-primary">
                                                        <i class="material-icons-outlined">delete</i>
                                                    </a>
                                                </div>

                                                <img v-show="file.type == 'image'" class=""
                                                    style="float: none; max-width:100%;height: 139px; object-fit: contain;"
                                                    :src="file.ico">
                                                <div v-show="file.type != 'image'"
                                                    style="height:139px; max-width:100%; background-color:#f5f5f5; border-radius: 5px">
                                                    <span class="material-icons-outlined "
                                                        style="font-size:70px; margin-top:20%;">insert_drive_file</span>
                                                    <p class="">{{ getExt(file.name) }}</p>
                                                </div>
                                            </div>
                                            <div class="d-md-none text-center" v-if="edit">
                                                <a v-tooltip href="#" @click.prevent="copyToClipboard(file.path)"
                                                    title="Skopiuj link" class="me-2 btn btn-icon-sm btn-primary">
                                                    <i class="material-icons-outlined">link</i>
                                                </a>
                                                <a v-tooltip target="_blank" :href="'/' + file.path" title="Podgląd"
                                                    class=" me-2 btn btn-icon-sm btn-primary">
                                                    <i class="material-icons-outlined">search</i>
                                                </a>
                                                <a v-tooltip v-if="acl.delete" @click.prevent="deleteFile(file)" href="#"
                                                    title="Usuń" class=" me-1 delete_file_link btn btn-icon-sm btn-primary">
                                                    <i class="material-icons-outlined">delete</i>
                                                </a>
                                            </div>
                                            <span style="cursor:pointer; " class="search-filename d-block p-2 cut-text">{{
                                                file.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </DIV>
                            <div class="text-right">
                                <button class="btn btn-icon-sm btn-outline-secondary me-1" v-show="page > 1"
                                    @click.prevent="reloadFolder(folder_path, page - 1)">
                                </button> Strona {{ page }}/{{ pages }} <button v-show="page < pages"
                                    class="ml-1 btn btn-icon-sm btn-outline-secondary"
                                    @click.prevent="reloadFolder(folder_path, page + 1)">></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '../../api/axios';
import MagicoModal from '../../components/MagicoModal.vue'
import FolderTree from './FolderTree.vue';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'


export default {
    components: {
        // vueDropzone: vue2Dropzone
        'b-modal': MagicoModal,
        FolderTree,
        vueDropzone: vue2Dropzone,
    },
    props: ['value', 'filtr', 'remember', 'choose', 'folder', 'hidefolders'],
    created: function () {
        if (this.choose) {
            this.edit = false;
        }
        this.selected = this.value;
        this.items = [];
        this.getServers();
        this.getFolderList();
        if (this.folder) {
            this.reloadFolder('/files/' + this.folder);
        } else {
            this.reloadFolder('/files/');
        }
    },
    computed: {
        filesFilteredList() {
            return this.files.filter((samsung) => {
                console.log('X', samsung);
                return samsung.name.toLowerCase().includes(this.search_name.toLowerCase());
            });
        },
        baseUrl: function () {
            return ''
        }
    },
    methods: {
        openUploadFile() {
            //tworzymy sesje z nazwa folderu gdzie będziemy wgrywać.
            // axios.post( 'panel/api/files/folder', {
            //     path: this.folder_path,
            // })
            //this.isUploadBox = true;
        },
        getServerLabel() {
            if (this.servers) {
                let i = this.servers.find(element => element.name == this.selectedServer);
                if (i) {
                    return i.label;
                }
            }
        },
        getExt: function (n) {
            return n.split('.').reverse()[0].toUpperCase();
        },
        createFolder() {
            let vm = this;
            var folder = this.folder_path;
            if (folder.substring(folder.length - 1) != '/') {
                folder = folder + '/'
            }
            axios.post('api/v3/filemanager/folder', {
                path: folder,
                name: this.new_folder_name,
                adapter: this.selectedServer,
            }).then(() => {
                this.$toast('success', 'Stworzono folder ' + vm.new_folder_name.name)
                vm.getFolderList(folder + vm.new_folder_name);
                vm.new_folder_name = '';
                vm.isFolderBox = false;
            })
        },
        progress(e) {
            this.amount = e;
            // file upload progress
            // returns false if progress is not computable
            console.log('progress', e);
        },
        startUpload(e) {
            this.isUpload = true;
            this.amount = 0;
            // file upload start event
            console.log('onStart', e);
        },
        finishUpload: function (e) {
            console.log('onFinish', e);
            //var response = JSON.parse(e.target.response);
            //this.isUploadBox = false;
            this.isUpload = false;
            this.isUploadBox = false;
            if (e.target.status == 422) {
                var j = JSON.parse(e.target.response);
                this.$toast('error', j.error);
            } else {
                this.$toast('success', 'Wgrano Plik na serwer');
                this.reloadFolder(this.folder_path);
            }
        },
        permitDelete: function () {
            var path = '';
            let vm = this;
            if (this.deleteModal.type == "file") {
                path = this.deleteModal.item.path;
                axios.post('api/v3/filemanager/delete', {
                    path: path,
                    adapter: this.selectedServer,

                }).then(() => {
                    this.$toast('Usunięto plik!')
                    vm.deleteModal.modal = false;
                    vm.reloadFolder(vm.folder_path, vm.page);
                    vm.getFolderList();
                });
            } else {
                path = this.deleteModal.item.chain
                axios.post('api/v3/filemanager/folder/delete', {
                    path: path,
                    adapter: this.selectedServer,
                }).then(() => {
                    this.$toast('Usunięto folder!')
                    vm.deleteModal.modal = false;
                    vm.reloadFolder(vm.folder_path, vm.page);
                    vm.getFolderList();
                }, error => {
                    if (error.response.data && error.response.data.error == 'not_empty') {
                        this.$toast('Folder nie jest pusty. Usuń najpierw z niego wszystkie pliki.')
                        vm.deleteModal.modal = false;
                    }
                });
            }
        },
        getFolderList(active = null) {
            console.log('msa', active);
            this.loadingFolder = true;
            let vm = this;
            axios.get('api/v3/filemanager/folders', {
                params: {
                    adapter: this.selectedServer,
                }
            }).then((response) => {
                vm.treeData = response.data.data;
                //vm.acl = response.data.data.acl;
                if (active && active != 'files/') {
                    if (active.substring(active.length - 1) != '/') {
                        active = active + '/'
                    }
                    vm.treeData.isActive = false;
                    console.log('toactive', active.replace('files/', '/'));
                    vm.resetItems(vm.treeData.children, {
                        chain: active.replace('files/', '/')
                    });
                    vm.loadingCustomPath = false;
                }
                vm.loadingFolder = false;
            })
        },
        copyToClipboard: function (str) {
            this.$toast('success', 'Skopiowano do schowka!');
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        getServers: function () {
            let vm = this;
            axios.get('api/v3/filemanager/server').then(response => {
                vm.servers = response.data;
            });
        },
        reloadFolder(path, page = 1) {
            this.loading = true;
            let vm = this;
            if (this.firstLoad) {
                this.loadingCustomPath = true;
            }
            this.page = page;
            this.isLoading = true;
            axios.get('api/v3/filemanager/files', {
                params: {
                    folder: path,
                    filtr: this.filtr,
                    name: this.search_name,
                    page: page,
                    limit: 30,
                    remeber: this.firstLoad ? 1 : 0,
                    adapter: this.selectedServer,
                }
            }).then((response) => {
                vm.loading = false;
                if (vm.firstLoad) {
                    setTimeout(function () {
                        vm.getFolderList(response.data.path);
                    }, 500);
                }
                if (response.data.path == 'files/') {
                    this.loadingCustomPath = false;
                }
                vm.firstLoad = false;
                vm.files = response.data.data;
                vm.folder_path = response.data.path;
                vm.openUploadFile();
                console.log(response.data);
                vm.isLoading = false;
                vm.pages = response.data.pages;
            })
        },
        resetItems(items, event, parent = null) {
            let vm = this;
            items.forEach((item) => {
                item.parent = parent;
                console.log('reset items', item.chain);
                if (event.chain == item.chain) {
                    vm.reloadFolder(event.chain);
                    item.isActive = true
                    item.isOpen = true;
                    if (item.parent) {
                        vm.openAbove(item.parent)
                    }
                } else {
                    item.isActive = false;
                }
                if (Array.isArray(item.children)) {
                    item.children = vm.resetItems(item.children, event, item);
                }
            })
            return items;
        },
        openAbove: function (item) {
            item.isOpen = true;
            if (item.parent) {
                this.openAbove(item.parent);
            }
        },
        uploadSuccessAll: function (e) {
            console.log('onFinishAll', e);
            //this.isUploadBox = false;
            this.isUpload = false;
            this.isUploadBox = false;
            // this.$toast('success', 'Wgrano All na serwer');
            this.reloadFolder(this.folder_path);
            this.$refs.myVueDropzone.removeAllFiles(true);
        },

        uploadSuccess: function (e) {
            this.$toast(e.name + ' ' + 'Wgrano Plik na serwer');
            this.$emit('uploadSuccess')
            // console.log('onFinish', e);
            // this.isUploadBox = false;
            // this.isUpload = false;
            // this.isUploadBox = false;
            // this.reloadFolder(this.folder_path);
            // this.$refs.myVueDropzone.removeAllFiles(true);
        },
        uploadFail: function (e, response) {
            console.log('fail', e, response);
            this.$toast(e.name + ' ' + response.error);
        },
        sendingFile: function (e) {
            this.isUpload = true;
            this.amount = 0;
            // file upload start event
            console.log('onStart', e);
        },
        sendingEvent(file, xhr, formData) {
            formData.append('path', this.folder_path);
            formData.append('adapter', this.selectedServer);
        },
        deleteFile: function (file) {
            this.deleteModal.type = "file";
            this.deleteModal.modal = true;
            this.deleteModal.item = file;
        },
        deleteFolder: function (file) {
            this.deleteModal.modal = true;
            this.deleteModal.item = file;
            this.deleteModal.type = "folder";
        },
        addItem(event) {
            if (event.chain == this.treeData.chain) {
                this.treeData.isActive = true;
            } else {
                this.treeData.isActive = false;
            }
            //przeladownie foldewru glonwgo
            if (event.chain === '/') {
                this.reloadFolder(event.chain);
            }
            this.treeData.children = this.resetItems(this.treeData.children, event);
            console.log('add-item', event, 'reset')
            //alert(event.chain);
        },
        clickImage(item) {
            // console.log(item);
            this.selected = item;
            this.change();
            this.$emit('choose-file', item);
        },
        change() {
            this.$emit('input', this.selected.path);
            this.$emit('change', true);
            //this.isOpen =false;
        }
    },
    data: function () {
        return {
            selectedServer: 'local',
            edit: true,
            folders: ['folder1', 'folder2', 'folder3'],
            files: [],
            servers: [],
            firstLoad: true,
            loading: false,
            loadingFolder: false,
            isUploadBox: false,
            isFolderBox: false,
            isOpen: true,
            isUpload: false,
            amount: 40,
            buffer: 100,
            loadingCustomPath: false,
            deleteModal: {
                modal: false,
                item: {},
                type: 'file'
            },
            treeData: {
                isActive: true,
                name: 'Pliki',
                chain: '/',
                isOpen: true,
                children: [],
            },
            items: [],
            selected: 0,
            placeholder: 'wybierz jakis plik',
            folder_path: 'Folder głowny',
            //treeData: treeData,
            isLoading: false,
            new_folder_name: '',
            uploadStart: false,
            search_name: '',
            page: 1,
            pages: 1,
            acl: {},
            dropzoneOptions: {
                url: axios.defaults.baseURL + '/api/v3/filemanager/upload',
                thumbnailWidth: 150,
                maxFilesize: 128,
                parallelUploads: 1,
                headers: {
                    "Authorization": "Bearer " + this.$store.state.auth.access_token
                }
            },
        }
    },
}


</script>
<style>
.hover-li:hover {
    background-color: #f2ffff
}

.hover-li a {
    position: relative;
    top: 2px
}

.image-caption:hover .choose_icon {
    display: block;
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    height: 140px;
    width: 88%
}

.choose_icon {
    display: none
}

.buttons_edit {
    display: none
}

.image-caption:hover .buttons_edit {
    display: block;
    top: 30%;
    position: absolute;
    height: 140px;
    width: 88%
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    height: 2em;
    white-space: nowrap;
}

.image-caption:hover .cut-text {
    position: relative;
    z-index: 1000;
    background-color: white;

    text-overflow: none;
    height: auto;
    overflow: auto;
    width: 100%;

    white-space: normal;
    overflow-wrap: break-word;
}
</style>