<template>
  <div v-if="isLogged">

    <!-- Layout wrapper -->
    <div id="layout" class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
        <Sidebar></Sidebar>

        <!-- Layout container -->
        <div class="layout-page">
          <!-- Navbar -->

          <nav v-if="me"
            class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar">
            <div id="layout-menu-toggle"
              class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="bx bx-menu bx-sm"></i>
              </a>
            </div>
            <div class="w-50">
              {{ $store.state.barTitle ? $store.state.barTitle : '' }}
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <!-- Search -->
              <!-- /Search -->
              <ul class="navbar-nav flex-row align-items-center ms-auto">
                <!-- Style Switcher -->
                <li class="nav-item me-2 me-xl-0">
                  <a class="nav-link style-switcher-toggle hide-arrow" href="javascript:void(0);">
                    <i class="bx bx-sm"></i>
                  </a>
                </li>

                <li>
                  <ConnectedUsers :users="$store.state.connectedUsers"></ConnectedUsers>
                </li>
                <!--/ Style Switcher -->
                <!-- <UserApps></UserApps> -->
                <li>
                  <FontSizeChanger></FontSizeChanger>
                </li>
                <li @click="switchTheme" class="nav-item me-2 me-xl-0">
                  <a class="nav-link style-switcher-toggle hide-arrow" href="javascript:void(0);">
                    <i class="bx bx-sm bx-moon"></i>
                  </a>
                </li>
                <UserNotification></UserNotification>
                <UserMenu></UserMenu>
              </ul>
            </div>

            <!-- Search Small Screens -->
            <div class="navbar-search-wrapper search-input-wrapper d-none">
              <input type="text" class="form-control search-input container-xxl border-0" placeholder="Search..."
                aria-label="Search..." />
              <i class="bx bx-x bx-sm search-toggler cursor-pointer"></i>
            </div>
          </nav>
          <div :class="$route.meta.disableContainer ? '' : 'content-wrapper'" class=" ">
            <router-view :key="$route.fullPath" v-if="me"></router-view>
            <div v-if="!me" class="text-center mt-5 pt-5">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div> 
              <div>
                Ładowanie aplikacji
              </div>
            </div>
            <footer v-if="!$route.meta.disableContainer" class="content-footer footer bg-footer-theme">
              <div class="container-xxl d-flex flex-wrap justify-content-end py-2 flex-md-row flex-column">
                <div class="mb-2 mb-md-0"><a title="Magico Software" href="https://magico.pl"><img
                      src="@/assets/icons/magico.svg" height="40px"></a>
                </div>
              </div>
            </footer>
          </div>

          <!-- / Content -->

          <!-- / Footer -->

        </div>
        <!-- Content wrapper -->
      </div>
      <!-- / Layout page -->
    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle"></div>

    <!-- Drag Target Area To SlideIn Menu On Small Screens -->
    <div class="drag-target"></div>
  </div>
  <div id="app" class="" v-else>
    <Forgot v-if="$route.name == 'forgot'"></Forgot>
    <Login v-else @login="updateLogged"></Login>
  </div>
</template>
<script>
import api from './api/api';
import ConnectedUsers from './components/board/ConnectedUsers.vue';
import Forgot from './components/Forgot.vue';
import Login from './components/Login.vue';
import Sidebar from './components/template/Sidebar.vue';
import FontSizeChanger from './components/template/FontSizeChanger.vue';
import UserMenu from './components/template/UserMenu.vue';
import UserNotification from './components/template/UserNotification.vue';
export default {
  components: { Login, Sidebar, UserMenu, UserNotification, Forgot, ConnectedUsers, FontSizeChanger },
  data: function () {
    return {
      me: null,
      isLogged: localStorage.getItem('algorytm_token') || null
    }
  },
  created: function () {
    this.$store.commit('access_token', localStorage.getItem('algorytm_token'));
  },
  mounted() {
    this.getMe();
    if (localStorage.getItem('algorytm-theme') == 'dark') {
      this.setDarkTheme();
    }
  },
  methods: {
    updateLogged: function (e) {
      this.isLogged = e;
      if (this.isLogged) this.getMe();
    },
    getMe: function () {
      if (!this.isLogged) return;
      api.me().then((response) => {
        this.me = response.data.data;
        this.$store.commit('setUser', this.me);
      })
    },
    switchTheme: function () {
      if (localStorage.getItem('algorytm-theme') != 'dark') {
        this.setDarkTheme();
        localStorage.setItem('algorytm-theme', 'dark')
      } else {
        this.setLightTheme();
        localStorage.setItem('algorytm-theme', 'light')
      }
    },
    setDarkTheme: function () {
      document.getElementById('dark-theme').setAttribute('href', document.getElementById('dark-theme').getAttribute('data-href'))
      document.getElementById('default-theme').setAttribute('href', '');
      document.body.classList.add('dark-style')
      document.body.classList.remove('light-style')
    },
    setLightTheme: function () {
      document.getElementById('default-theme').setAttribute('href', document.getElementById('default-theme').getAttribute('data-href'))
      document.getElementById('dark-theme').setAttribute('href', '')
      document.body.classList.remove('dark-style')
      document.body.classList.add('light-style')
    }
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.f-cap::first-letter {
  text-transform: capitalize;
}
</style>
