import axios from './axios.js'
import store from '../store/index.js'

export default {
    init() {
        axios.get('/api/slowniki').then(response => {
            store.commit('slownik_contest', response.data.data.slownik_contest);
            store.commit('slownik_client', response.data.data.slownik_client);
            store.commit('slownik_project_settlements', response.data.data.slownik_project_settlements);
            store.commit('slownik_project_contract', response.data.data.slownik_project_contract);
            store.commit('slownik_user', response.data.data.slownik_user);
            store.commit('slownik_user_position', response.data.data.slownik_user_position);
            store.commit('slownik_groups', response.data.data.slownik_groups);
            store.commit('slownik_project', response.data.data.slownik_project);
        })
    }
}