<template>
    <!-- Notification -->
    <li class="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
        <a class="nav-link dropdown-toggle hide-arrow" @click="clickMarkAsRead()" data-bs-toggle="dropdown"
            data-bs-auto-close="outside" aria-expanded="false">
            <i class="bx bx-bell bx-sm"></i>
            <span class="badge bg-danger rounded-pill badge-notifications">{{ notificataionCount }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end py-0">
            <li class="dropdown-menu-header border-bottom">
                <div class="dropdown-header d-flex align-items-center py-3">
                    <h5 class="text-body mb-0 me-auto">Notification</h5>
                    <a href="javascript:void(0)" class="dropdown-notifications-all text-body" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="Mark all as read"><i class="bx fs-4 bx-envelope-open"></i></a>
                </div>
            </li>
            <li class="dropdown-notifications-list scrollable-container" style="overflow-y: auto;max-height:400px;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read"
                        v-for="notification, index in notifications" :key="index" @click="clickNotification(notification)">
                        <div class="d-flex">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar">
                                    <span class="avatar-initial rounded-circle bg-label-warning"><i
                                            class="bx bx-error"></i></span>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">{{ notification.notification_title }}</h6>
                                <p class="mb-0">{{ notification.notification_body }}</p>
                                <small class="text-muted">{{ notification.notification_created_at }}</small>
                            </div>
                            <!-- <div class="flex-shrink-0 dropdown-notifications-actions">
                                <a href="javascript:void(0)" class="dropdown-notifications-read"><span
                                        class="badge badge-dot"></span></a>
                                <a href="javascript:void(0)" class="dropdown-notifications-archive"><span
                                        class="bx bx-x"></span></a>
                            </div> -->
                        </div>
                    </li>
                </ul>
            </li>
            <li class="dropdown-menu-footer border-top">
                <a @click.prevent="clickDeleteAll" href="#" class="dropdown-item d-flex justify-content-center p-3">
                    Usuń wszystkie powiadomienia
                </a>
            </li>
        </ul>
    </li>
    <!--/ Notification -->
</template>
<script>
import api from '../../api/api';

export default {
    data: function () {
        return {
            notificataionCount: 0,
            notifications: [],
        }
    },
    created: function () {
        this.getPageNotifications();
    },
    methods: {
        clickDropdown() {
            alert(1)
        },
        clickDeleteAll() {
            api.removePageNotifications(this.notifications).then(() => {
                this.getPageNotifications();
            });
        },
        clickMarkAsRead() {
            api.readPageNotifications(this.notifications).then(() => {
                this.getPageNotifications();
            });
        },
        clickNotification(notification) {
            this.$router.push('/' + notification.notification_url)
        },
        getPageNotifications() {
            api.getPageNotifications().then(response => {
                this.notifications = response.data.data;
                this.notificataionCount = response.data.count_unread;
            })
        }
    }
}
</script>