export default {
    menu: [
        {
            "name": '',
            items: [
                {
                    'name': 'dashboard',
                    'label': 'Kokpit',
                    'ico': 'bx bx-home-circle',
                    'href': '/',
                    items: []
                },
                {
                    'name': 'chat',
                    'label': 'Komunikacja',
                    'ico': 'bx bx-chat',
                    'href': '/chat',
                    items: []
                },
                {
                    'name': 'calendar',
                    'label': 'Terminy',
                    'ico': 'bx bx-calendar',
                    'href': '/calendar',
                    items: []
                },

                {
                    'name': 'project',
                    'label': 'Projekty',
                    'ico': 'bx bx-category-alt',
                    'href': '',
                    'open': false,
                    items: [
                        {
                            'name': 'project',
                            'label': 'Wszystkie',
                            'ico': 'bx bx-home-circle',
                            'href': '/project',
                        }
                    ]
                },
                {
                    'name': 'app',
                    'label': 'Zarządzanie',
                    'ico': 'bx bx-cog',
                    'href': '',
                    'open': false,
                    items: [
                        {
                            'name': 'client',
                            'label': 'Klienci',
                            'ico': 'bx bx-cog',
                            'href': '/client',
                        },

                        {
                            'name': 'user',
                            'label': 'Użytkownicy',
                            'ico': 'bx bx-user',
                            'href': '/user',
                        },
                        {
                            'name': 'dictionary',
                            'label': 'Słowniki',
                            'ico': 'bx bx-user',
                            'href': '/dictionary',
                        },
                        {
                            'name': 'storage',
                            'label': 'Pliki i Multimedia',
                            'ico': 'bx bx-user',
                            'href': '/filemanager',
                        },

                    ]
                },


            ],
        }
    ]
}