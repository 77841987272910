<template>
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" @mouseover="onHover" @mouseleave="onBlur">

        <div class="app-brand demo">
            <a href="/" class="app-brand-link py-3">
                <span class="app-brand-logo demo">
                    <img v-if="(hover || !collapsed) && collapsed || hover || expanded" alt="Vue logo" width="150px"
                        height="36px" src="../../assets/logo.png">
                    <img v-if="collpase && !hover && !expanded" alt="Vue logo" height="36px" src="../../assets/icon.png">

                </span>
            </a>
            <a href="javascript:void(0);" @click.prevent="collpase"
                :class="hover && !collapsed && !mobile ? 'd-md-block d-none' : ''"
                class="layout-menu-toggle menu-link text-large ms-auto" id="layout-menu-toggle">
                <i class="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
        </div>

        <div class="menu-inner-shadow"></div>

        <ul class="menu-inner py-1">

            <template v-for="m, index in menu">
                <!-- Apps & Pages -->
                <li v-if="m.name" :key="index" class="menu-header small text-uppercase">
                    <span class="menu-header-text">{{ m.items }}</span>
                </li>
                <li :key="index + key" v-for="item, key in m.items" class="menu-item "
                    :class="{ active: $route.meta.menu == item.name, open: item.open }">
                    <router-link v-if="item.items.length == 0" :to="item.href" class="menu-link "
                        :class="{ 'menu-toogle': item.items.length > 0 }">
                        <i class="menu-icon tf-icons" :class="item.ico"></i>
                        <div data-i18n="Dashboards">{{ item.label }}</div>
                    </router-link>
                    <a class="menu-link menu-toggle" v-if="item.items.length > 0"
                        :class="{ active: $route.meta.menu == item.name }" data-bs-toggle="collapse"
                        :href="'#collapse' + item.name" role="button" aria-expanded="false" aria-controls="collapseExample">

                        <i class="menu-icon tf-icons" :class="item.ico"></i>
                        <div data-i18n="Dashboards">{{ item.label }}</div>
                    </a>
                    <ul class="menu-sub collapse" :class="{ show: $route.meta.menu == item.name }"
                        :id="'collapse' + item.name" v-if="item.name == 'project'">
                        <li v-for="it, key in $store.state.slownik_project" :key="key" class="menu-item "
                            :class="{ active: $route.params.id == it.project_id }">
                            <router-link :to="'/project/' + it.project_id + '/summary?'" class="menu-link">
                                <div data-i18n="Accordion">{{ it.project_name }} </div>
                            </router-link>
                        </li>
                    </ul>
                    <ul class="menu-sub collapse" :class="{ show: $route.meta.menu == item.name }"
                        :id="'collapse' + item.name" v-if="item.items.length > 0">
                        <li v-for="it, key in item.items" :key="key" class="menu-item "
                            :class="{ active: $route.meta.submenu == it.name }">
                            <router-link :to="it.href" class="menu-link">
                                <div data-i18n="Accordion">{{ it.label }} </div>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </template>

        </ul>
    </aside>
</template>
<script>
import menu from '../../data/menu'
export default {
    name: 'Sidebar',
    data: function () {
        return {
            menu: menu.menu,
            collapsed: false,
            hover: true,
            expanded: false,
            mobile: document.body.clientWidth < 992
        }
    },
    mounted() {
        let vm = this;
        document.getElementById('layout-menu-toggle').addEventListener('click', function () {
            if (document.body.clientWidth < 1200) {
                if (!document.getElementById('layout').classList.contains('layout-menu-expanded')) {


                    vm.expanded = true;
                    document.getElementById('layout').classList.add('layout-transitioning');
                    document.getElementById('layout').classList.add('layout-menu-expanded');

                    setTimeout(function () {
                        document.getElementById('layout').classList.remove('layout-transitioning');

                    }, 300)
                }
            }
        })
    },
    methods: {
        onHover: function () {
            if (document.getElementById('layout').classList.contains('layout-menu-collapsed')) {
                document.getElementById('layout').classList.add('layout-menu-hover');
                this.hover = true

            }
        },
        onBlur: function () {
            if (document.getElementById('layout').classList.contains('layout-menu-collapsed')) {
                document.getElementById('layout').classList.remove('layout-menu-hover');
                this.hover = false

            }
        },
        collpase: function () {
            if (this.expanded) {
                console.log('here');
                this.expanded = false;
                document.getElementById('layout').classList.add('layout-transitioning');
                document.getElementById('layout').classList.remove('layout-menu-expanded');

                setTimeout(function () {
                    document.getElementById('layout').classList.remove('layout-transitioning');

                }, 300)
                return
            }
            if (document.getElementById('layout').classList.contains('layout-menu-collapsed')) {

                this.collapsed = document.body.clientWidth < 1200 ? true : false;
                document.getElementById('layout').classList.remove('layout-menu-collapsed');
            } else {
                this.collapsed = false;
                document.getElementById('layout').classList.add('layout-menu-collapsed');
                this.hover = true;
            }
        }
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: height 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    height: 0px;
}
</style>