<template>
    <div class="file-choser">
        <div @click="dismiss()" class="file-background"></div>
        <div class="file-manager">
            <div @click="dismiss()" class="file-close">&times;</div>
            <FileManager  @choose-file="chooseFile" :choose="true"></FileManager>
        </div>
    </div>
</template>
        <script>
import FileManager from '../../views/filemanager/FilemanagerIndex.vue'
export default {
    name: "fileChooser",
    components: {
        FileManager
    },
    data() {
        return {
            parent: null,
            open:false,
        }
    },
    beforeMount() {
        this.setupContainer()
    },
    mounted() {
        setTimeout(() => {
            this.parent.insertAdjacentElement('afterbegin', this.$el);
            
        }, 150);

        
        console.log(this);

        // this.showNotice();
        // eventBus.on('toast-clear', this.dismiss)
    },
    methods: {
        chooseFile: function (e) {
            this.$emit('choose-file', e);
            this.dismiss();
            //console.log('chosed',e);
        },
        setupContainer() {
            this.parent = document.querySelector('.file-chooser');
            // No need to create them, they already exists
            if (this.parent && this.parentBottom) return;
            if (!this.parent) {
                this.parent = document.createElement('div');
                this.parent.className = 'file-chooser';
            }
            const container = document.body;
            container.appendChild(this.parent);
        },
        dismiss() {
            setTimeout(() => {
                // this.onDismiss.apply(null, arguments);
                this.$destroy();
                if (typeof this.$el.remove !== 'undefined') {
                    this.$el.remove()
                } else {
                    this.$el.parentNode.removeChild(this.$el)
                }
            }, 150)

        },
    },
    beforeDestroy: function () {
    }
}
</script>

    <style>
    .file-choser {}
    
    .file-close {
        font-size: 30px;
        position: absolute;
        right: 20px;
        top: 5px;
        cursor: pointer;
    }
    
    .file-background {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2200;
        background-color: black;
        opacity: 0.4;
    }
    
    .file-manager {
        position: fixed;
        width: 90%;
        height: 90%;
        left: 5%;
        top: 5%;
        border-radius: 10px;
        padding: 15px;
        z-index: 2201;
        background-color: white;
    
    }
    </style>

