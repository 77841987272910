<template>
    <div class="">


        <div class="container-xxl flex-grow-1 container-p-y" v-if="!isLoading">
            <div class="card">
                <div class="card-body">
                    <FullCalendar :options="calendarOptions" ref="fullCalendar" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'

import interactionPlugin from '@fullcalendar/interaction'
import plLocale from "@fullcalendar/core/locales/pl";
import axios from 'axios'

export default {
    components: {
        FullCalendar,
    },
    data() {
        return {
            isLoading: false,
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                locale: plLocale,
                dateClick: this.handleDateClick,
                eventClick: this.eventDateClick,
                events: [
                ],
                headerToolbar: {
                    start: 'title', // will normally be on the left. if RTL, will be on the right
                    center: '',
                    end: 'today,timeGridWeek,dayGridMonth prev,next' // will normally be on the right. if RTL, will be on the left
                }
            }
        }
    },
    methods: {
        handleDateClick: function (arg) {
            this.getEvents();
            console.log('date click! ' + arg.dateStr.extendedProps)
        },
        eventDateClick: function (arg) {
            // this.getEvents();
            console.log('event click! ', arg.event.extendedProps.task_id);
            this.$router.push('/task/' + arg.event.extendedProps.project_id + '/show/' + arg.event.extendedProps.task_id)
        },
        getEvents: function () {
            axios.get('https://algorytm.serwer.magico.pl/api/api/v1/task/calendar').then(response => {
                this.calendarOptions.events = response.data
            })
        }
    },
    created() {
        this.getEvents()
        this.id = this.$route.params.id;
    }
}
</script>