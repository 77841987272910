import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/forgot',
    component: require('../views/About.vue').default,
    name: 'forgot',
    meta: { menu: 'project', submenu: 'chat' }
  },
  {
    path: '/chat',
    component: require('../views/chat/ChatIndex.vue').default,
    meta: { menu: 'chat', submenu: 'chat' }
  },
  {
    path: '/calendar',
    component: require('../views/calendar/CalendarIndex.vue').default,
    meta: { menu: 'calendar', submenu: 'calendar' }
  },
  {
    path: '/filemanager',
    component: require('../views/filemanager/FilemanagerIndex.vue').default,
    meta: { menu: 'app', submenu: 'storage' }
  },
  {
    path: '/dictionary/:dictionary_id/items/:id/edit',
    name: 'DictionaryItemsEdit',
    component: () => import('../views/dictionary/DictionaryItemsEdit.vue'),
    meta: {
      menu: 'app',
      submenu:'dictionary'
    }
  }, {
    path: '/dictionary/:dictionary_id/items/create',
    name: 'DictionaryItemsEdit',
    component: () => import('../views/dictionary/DictionaryItemsEdit.vue'),
    meta: {
      menu: 'app',
      submenu:'dictionary'
    }
  },
  {
    path: '/dictionary/:id/items',
    name: 'DictionaryItemsIndex',
    component: () => import('../views/dictionary/DictionaryItemsIndex.vue'),
    meta: {
      menu: 'app',
      submenu:'dictionary'
    }
  },

  {
    path: '/dictionary',
    name: 'DictionaryIndex',
    component: () => import('../views/dictionary/DictionaryIndex.vue'),
    meta: {
      menu: 'app',
      submenu:'dictionary'
    }
  },
  {
    path: '/project/create',
    name: 'ProejctCreate',
    component: () => import('../views/project/ProjectEdit.vue'),
    meta: {
      menu: 'project',
    }
  },
  {
    path: '/project',
    name: 'ProjectIndex',
    component: () => import('../views/project/ProjectIndex.vue'),
    meta: {
      menu: 'project',
      submenu: 'project',
    }
  },
  {
    path: '/project/:id/edit',
    name: 'ProjectShow',
    component: () => import('../views/project/ProjectEdit.vue'),
    meta: {
      menu: 'project',
      submenu: 'project_item',
    }
  },
  {
    path: '/project/:id/file',
    name: 'ProjectFile',
    component: () => import('../views/project/ProjectFile.vue'),
    meta: {
      menu: 'project',
      submenu: 'project_item',
    }
  }
  , {
    path: '/project/:id/calendar',
    name: 'ProjectCalendar',
    component: () => import('../views/project/ProjectCalendar.vue'),
    meta: {
      menu: 'project',
      submenu: 'project_item',
    }
  },
  {
    path: '/project/:id/task',
    name: 'ProjectShow',
    component: () => import('../views/project/ProjectTask.vue'),
    meta: {
      menu: 'project',
      submenu: 'project_item',
    }
  },
  {
    path: '/task/:project_id/show/:id',
    name: 'ProjectShow',
    component: () => import('../views/project/ProjectTaskShow.vue'),
    meta: {
      menu: 'project',
      submenu: 'project_item',
    }
  },
  {
    path: '/project/:id/summary',
    name: 'ProjectSummary',
    component: () => import('../views/project/ProjectSummary.vue'),
    meta: {
      menu: 'project',
      submenu: 'project_item',
    }
  },
  {
    path: '/client',
    name: 'ClientIndex',
    component: () => import('../views/client/ClientIndex.vue'),
    meta: {
      menu: 'app',
      submenu: 'client',
    }
  },
  {
    path: '/client/:id/comment',
    name: 'ClientComment',
    component: () => import('../views/client/ClientComment.vue'),
    meta: {
      menu: 'app',
      submenu: 'client',
    }
  }, {
    path: '/client/:id/show',
    name: 'ClientShow',
    component: () => import('../views/client/ClientShow.vue'),
    meta: {
      menu: 'app',
      submenu: 'client',
    }
  },
  {
    path: '/client/add',
    name: 'ClientAdd',
    component: () => import('../views/client/ClientEdit.vue'),
    meta: {
      menu: 'app',
      submenu: 'client',
    }
  }, {
    path: '/client/:id/edit',
    name: 'ClientEdit',
    component: () => import('../views/client/ClientEdit.vue'),
    meta: {
      menu: 'app',
      submenu: 'client',
    }
  },
  {
    path: '/user/me',
    name: 'MeView',
    component: () => import('../views/user/MeAccountView.vue'),
    meta: {
      menu: 'dashboard',
      submenu: '',

    }
  },
  {
    path: '/user/me/secuity',
    name: 'MeView',
    component: () => import('../views/user/MeSecurityView.vue'),
    meta: {
      menu: 'dashboard',
      submenu: '',

    }
  },
  {
    path: '/user/me/notifications',
    name: 'MeView',
    component: () => import('../views/user/MeNotififactionsView.vue'),
    meta: {
      menu: 'dashboard',
      submenu: '',

    }
  },
  {
    path: '/user/:id',
    name: 'MeView',
    component: () => import('../views/user/UserAccountView.vue'),
    meta: {
      menu: 'app',
      submenu: 'user',

    }
  },
  {
    path: '/user/:id/secuity',
    name: 'MeView',
    component: () => import('../views/user/UserSecurityView.vue'),
    meta: {
      menu: 'app',
      submenu: 'user',

    }
  },
  {
    path: '/user/:id/notifications',
    name: 'MeView',
    component: () => import('../views/user/UserNotififactionsView.vue'),
    meta: {
      menu: 'app',
      submenu: 'user',

    }
  },
  {
    path: '/user',
    name: 'UserIndex',
    component: () => import('../views/user/UserIndex.vue'),
    meta: {
      menu: 'app',
      submenu: 'user',
    }
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import('../views/group/Group.vue'),
    meta: {
      menu: 'app',
      submenu: 'user',
    }
  }, {
    path: '/',
    name: 'DashboardView',
    component: () => import('../views/dashboard/DashboardView.vue'),
    meta: {
      menu: 'dashboard',
      submenu: '',
    }
  }, {
    path: '/board/:id',
    name: 'Board',
    component: () => import('../views/Board.vue'),
    meta: {
      menu: 'project',
      submenu: 'board',
      disableContainer: true,
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),

  // }
]

const router = new VueRouter({
  routes
})

export default router
