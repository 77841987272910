<template>
    <li v-if="active">
        <div class="hover-li d-flex align-items-center" :class="{ 'text-primary': item.isActive }"><span @click="toggle"
                class="material-icons-outlined me-1">{{ isOpen ? 'folder_open' :
                (item.children.length > 0 ? 'create_new_folder' : 'folder')
                }}</span>
            <span style="cursor:pointer;display: inline-block;" class="flex-fill" @click="clickName()">{{ item.name }}</span>
            <a v-tooltip title="Usun folder" @click="$emit('delete', item)" style="float:right" href="#"><span
                    v-if="item.chain != '/' && edit" class="material-icons-outlined text-danger">delete</span></a>
        </div>

        <ul style="list-style:none;" class="ps-3" v-show="isOpen || (item.isActive && create)"
            v-if="isFolder || item.isActive && create">
            <li v-show="item.isActive && create" class="text-success"><span style="position:relative; top:4px;"
                    class="material-icons-outlined text-success">create_new_folder</span><i>Nowy folder</i></li>

            <template v-for="(child, index) in item.children">
                <FolderTree :active="true" v-if="item.isOpen" @delete="$emit('delete', $event)" :item="child" :key="index"
                    style="cursor:pointer" class="item" :create="create" :edit="edit"
                    @add-item="$emit('add-item', $event)"></FolderTree>
            </template>

        </ul>
    </li>
    <li v-else>
        <div class="hover-li"><span @click="toggle"
                class="material-icons-outlined " style="position:relative; top:4px;">{{remote?'cloud':'folder'}}</span>
            <span style="cursor:pointer;width: 80%;display: inline-block;" @click="$emit('activate')">{{ label }}</span>
           
        </div>
    </li>
</template>
<script>
export default {
    name: 'FolderTree',
    props: {
        item: Object,
        create: Boolean,
        edit: Boolean,
        active: Boolean,
        label:String,
        remote:Boolean
    },
    created: function () {
        this.isOpen = this.item.isOpen;
        this.isActive = this.item.isActive;
    },
    watch: {
        item: function () {
            this.isOpen = this.item.isOpen;
            this.isActive = this.item.isActive;
        }
    },
    data: function () {
        return {
            isOpen: false,
            isActive: false,
            search_name: '',
        }
    },
    computed: {
        isFolder: function () {
            return this.item.children && this.item.children.length
        }
    },
    methods: {
        clickName: function () {
            this.item.isActive = !this.item.isActive
            if (this.isFolder) {
                this.isOpen = true;
            }
            this.$emit('add-item', this.item);
        },
        toggle: function () {
            console.log('toggle');
            if (this.isFolder) {
                this.isOpen = false;
            }
            //this.item.isActive = !this.item.isActive
            //this.$emit('add-item', this.item);
        },
        makeFolder: function () {
            if (!this.isFolder) {
                this.$emit('make-folder', this.item)
                this.isOpen = true
            }
        }
    }
}
</script>