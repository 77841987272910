<template>
    <div class="me-4">
        <div class="d-flex gap-2">
            <div v-for="item, k in users" :key="k" >
                <MagicoAvatar :user_id="item.id" :size="30"></MagicoAvatar>
            </div>
        </div>
    </div>
</template>
<script>
import MagicoAvatar from '../MagicoAvatar.vue';

export default {
    name: "ConnectedUsers",
    props: ["users"],
    methods: {
        getUserImage(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.avatar;
        },
    },
    components: { MagicoAvatar }
}
</script>