<template>
    <div class="mb-2">
        <a href="" @click.prevent="up" class="me-2"><svg width="24px" data-font-size="1" fill="grey" version="1.1" id="Warstwa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  x="0px" y="0px"
                viewBox="0 0 93.03 66.48" style="enable-background:new 0 0 93.03 66.48;" xml:space="preserve">
                <g>
                    <path d="M15.78,45.57l-6.9,20.91H0L22.59,0h10.36l22.68,66.48h-9.17l-7.1-20.91H15.78z M37.58,38.86l-6.51-19.14 c-1.48-4.34-2.47-8.28-3.45-12.13h-0.2c-0.98,3.95-2.07,7.99-3.35,12.03l-6.51,19.23H37.58z"></path>
                </g>
                <g>
                    <path d="M76.65,3.95v17.03h16.38v4.35H76.65v17.18h-4.64V25.33H55.63v-4.35h16.38V3.95H76.65z"></path>
                </g>
            </svg></a>
        <a href="" @click.prevent="down" class="me-2"><svg width="24px" data-font-size="-1" fill="grey" version="1.1" id="Warstwa_2"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 93.03 66.48" style="enable-background:new 0 0 93.03 66.48;" xml:space="preserve">
                <g>
                    <path d="M15.78,45.57l-6.9,20.91H0L22.59,0h10.36l22.68,66.48h-9.17l-7.1-20.91H15.78z M37.58,38.86l-6.51-19.14 c-1.48-4.34-2.47-8.28-3.45-12.13h-0.2c-0.98,3.95-2.07,7.99-3.35,12.03l-6.51,19.23H37.58z"></path>
                </g>
                <rect x="55.63" y="20.98" width="37.4" height="4.35"></rect>
            </svg></a>
    </div>
</template>
<script>
export default {
    methods: {
        up: function () {
            let size = parseInt(document.body.style['font-size'] || 16);
            document.body.style['font-size'] = (size + 1) + 'px';
            document.documentElement.style['font-size'] = (size + 1) + 'px';

        },
        down: function () {
            let size = parseInt(document.body.style['font-size']) || 16;
            document.body.style['font-size'] = parseInt(size - 1) + 'px';
            document.documentElement.style['font-size'] = parseInt(size - 1) + 'px';

        }
    }

}
</script>