import axios from './axios.js'
export default {
    getBaseUrl() {
        return process.env.VUE_APP_BASE_URL;
    },
    me() {
        return axios.get('auth/me')
    },
    getProject(projectId) {
        return axios.get('api/v1/project/' + projectId)
    },
    getProjectFile(projectId) {
        return axios.get('api/v1/project/' + projectId + '/file')
    },
    getProjectSnapshot(projectId) {
        return axios.get('api/v1/project/' + projectId + '/snapshot')
    },
    getPageNotifications() {
        return axios.get('api/v1/notification/pagenotification')
    },
    removePageNotifications(items) {
        return axios.post('api/v1/notification/pagenotification/clear', { clear: items })
    },
    readPageNotifications(items) {
        return axios.post('api/v1/notification/pagenotification/read', { read: items })
    }
}