import FileChooserModal from './FileChooserModal.vue';
import store from '../../store/index.js';


export const useManager = (Vue, globalOptions = {}) => {
    return {
        open(options) {
            const propsData = Object.assign({}, globalOptions, options);
            var app = new (Vue.extend(FileChooserModal))({
                el: document.createElement('div'),
                propsData,
                store
            })
            app.$on('choose-file', function (ev) {
                propsData.chooseCallback(ev)
            })
            return app;
        }
    }
};