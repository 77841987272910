import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    slownik_contest: [],
    slownik_client: [],
    slownik_project_settlements: [],
    slownik_project_contract: [],
    slownik_user_position: [],
    slownik_client_want_to: [],
    slownik_client_type: [],
    slownik_user: [],
    slownik_wojewodztwo: [],
    slownik_client_stage: [],
    slownik_client_group: [],
    slownik_client_economic_form: [],
    slownik_groups: {},
    slownik_project: [],
    connectedUsers: [],
    user: {},
    barTitle: '',
    pageTitle: '',
    users: [],
    auth: {
      access_token: '',
    },
  },

  mutations: {
    slownik_groups: function (state, data) {
      state.slownik_groups = data
    },
    setUsers: function (state, data) {
      state.users = data
    },
    setConnectedUsers: function (state, data) {
      state.connectedUsers = data
    },
    access_token: function (state, token) {
      state.auth.access_token = token
    },
    slownik_contest: function (state, slownik_contest) {
      state.slownik_contest = slownik_contest
    },
    slownik_client: function (state, slownik_client) {
      state.slownik_client = slownik_client
    },
    slownik_project: function (state, slownik_project) {
      state.slownik_project = slownik_project
    },
    slownik_project_settlements: function (state, slownik_project_settlements) {
      state.slownik_project_settlements = slownik_project_settlements
    },
    slownik_client_economic_form: function (state, slownik_client_economic_form) {
      state.slownik_client_economic_form = slownik_client_economic_form
    },
    slownik_client_group: function (state, slownik_client_group) {
      state.slownik_client_group = slownik_client_group
    },
    slownik_client_stage: function (state, slownik_client_stage) {
      state.slownik_client_stage = slownik_client_stage
    },
    slownik_wojewodztwo: function (state, slownik_wojewodztwo) {
      state.slownik_wojewodztwo = slownik_wojewodztwo
    },
    slownik_client_type: function (state, slownik_client_type) {
      state.slownik_client_type = slownik_client_type
    },
    slownik_client_want_to: function (state, slownik_client_want_to) {
      state.slownik_client_want_to = slownik_client_want_to
    },
    slownik_project_contract: function (state, slownik_project_contract) {
      state.slownik_project_contract = slownik_project_contract
    },
    slownik_user_position: function (state, slownik_user_position) {
      state.slownik_user_position = slownik_user_position
    },
    slownik_user: function (state, slownik_user) {
      state.slownik_user = slownik_user
      state.users = slownik_user;
    },
    setUser(state, data) {
      state.user = data;
    },
    setBarTitle(state, data) {
      state.barTitle = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
