import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "../src/scss/core.scss";
// import "../src/scss/theme-default.scss";

import 'boxicons/css/boxicons.min.css';
import FileChooserPlugin from './components/filemanager/FileChooserPlugin'
import VueI18n from 'vue-i18n'

import "bootstrap-icons/font/bootstrap-icons.css";
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/widgets/draggable'
import {
  Tooltip
} from "bootstrap";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pl';
import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'
require('dayjs/locale/pl')

require("fslightbox");

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
};
dayjs.locale('pl')
Vue.use(Toast, options);
//LANGI
Vue.use(FileChooserPlugin);


//Słowniki
import slownik from './api/slownik';
slownik.init();
dayjs.extend(customParseFormat)

window.magico = { shapes: {}, ui: {} };

// export for others scripts to use
window.jQuery = require('jquery');
window.$ = window.jQuery;

Vue.config.productionTip = false
Vue.directive('tooltip', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    new Tooltip(el, {
      trigger: 'hover',
      boundary: document.body
    })
  },
  update: function (el) {
    setTimeout(function () {
      if (el.title) {
        el.setAttribute('data-bs-original-title', el.title);
      }
      //console.log(el);
      if (Tooltip.getInstance(el)) {
        Tooltip.getInstance(el).update();
      }
    }, 250);
  }
})


// Vue.directive('lightbox', {
//   // When the bound element is inserted into the DOM...
//   inserted: function (el) {
//     el.addEventListener('click', function () {
//       el.lightbox = new window.FsLightbox();
//       el.lightbox.props.sources = [el.attributes.src.nodeValue];
//       console.log(el.attributes.src.nodeValue)
//       el.lightbox.open();
//     })
//   },

//   update: function () {

//   }
// })


Vue.use(VueI18n)
new Vue({
  router,
  store,
  i18n: new VueI18n({
    locale: 'pl',
    messages: {
      pl: require('./langs/pl.json'),
    }
  }),
  render: h => h(App)
}).$mount('#app')
