var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLogged)?_c('div',[_c('div',{staticClass:"layout-wrapper layout-content-navbar",attrs:{"id":"layout"}},[_c('div',{staticClass:"layout-container"},[_c('Sidebar'),_c('div',{staticClass:"layout-page"},[(_vm.me)?_c('nav',{staticClass:"layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme",attrs:{"id":"layout-navbar"}},[_vm._m(0),_c('div',{staticClass:"w-50"},[_vm._v(" "+_vm._s(_vm.$store.state.barTitle ? _vm.$store.state.barTitle : '')+" ")]),_c('div',{staticClass:"navbar-nav-right d-flex align-items-center",attrs:{"id":"navbar-collapse"}},[_c('ul',{staticClass:"navbar-nav flex-row align-items-center ms-auto"},[_vm._m(1),_c('li',[_c('ConnectedUsers',{attrs:{"users":_vm.$store.state.connectedUsers}})],1),_c('li',[_c('FontSizeChanger')],1),_c('li',{staticClass:"nav-item me-2 me-xl-0",on:{"click":_vm.switchTheme}},[_vm._m(2)]),_c('UserNotification'),_c('UserMenu')],1)]),_vm._m(3)]):_vm._e(),_c('div',{staticClass:"",class:_vm.$route.meta.disableContainer ? '' : 'content-wrapper'},[(_vm.me)?_c('router-view',{key:_vm.$route.fullPath}):_vm._e(),(!_vm.me)?_c('div',{staticClass:"text-center mt-5 pt-5"},[_vm._m(4),_c('div',[_vm._v(" Ładowanie aplikacji ")])]):_vm._e(),(!_vm.$route.meta.disableContainer)?_c('footer',{staticClass:"content-footer footer bg-footer-theme"},[_vm._m(5)]):_vm._e()],1)])],1)]),_c('div',{staticClass:"layout-overlay layout-menu-toggle"}),_c('div',{staticClass:"drag-target"})]):_c('div',{attrs:{"id":"app"}},[(_vm.$route.name == 'forgot')?_c('Forgot'):_c('Login',{on:{"login":_vm.updateLogged}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none",attrs:{"id":"layout-menu-toggle"}},[_c('a',{staticClass:"nav-item nav-link px-0 me-xl-4",attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"bx bx-menu bx-sm"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item me-2 me-xl-0"},[_c('a',{staticClass:"nav-link style-switcher-toggle hide-arrow",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"bx bx-sm"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link style-switcher-toggle hide-arrow",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"bx bx-sm bx-moon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-search-wrapper search-input-wrapper d-none"},[_c('input',{staticClass:"form-control search-input container-xxl border-0",attrs:{"type":"text","placeholder":"Search...","aria-label":"Search..."}}),_c('i',{staticClass:"bx bx-x bx-sm search-toggler cursor-pointer"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-xxl d-flex flex-wrap justify-content-end py-2 flex-md-row flex-column"},[_c('div',{staticClass:"mb-2 mb-md-0"},[_c('a',{attrs:{"title":"Magico Software","href":"https://magico.pl"}},[_c('img',{attrs:{"src":require("@/assets/icons/magico.svg"),"height":"40px"}})])])])
}]

export { render, staticRenderFns }