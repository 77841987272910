<template>
    <div class="authentication-wrapper authentication-cover">
        <div class="authentication-inner row m-0">
            <!-- /Left Text -->
            <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
                <div class="w-100 d-flex justify-content-center">
                    <img src="@/assets/login.png" class="img-fluid" alt="Login image" width="700"
                        data-app-dark-img="illustrations/boy-with-rocket-dark.png"
                        data-app-light-img="illustrations/boy-with-rocket-light.png" />
                </div>
            </div>
            <!-- /Left Text -->

            <!-- Login -->
            <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg bg-light p-sm-5 p-4">
                <div class="w-px-400 mx-auto">
                    <!-- Logo -->
                    <div class="app-brand mb-5">
                        <a href="/" class="app-brand-link gap-2">
                            <span class="app-brand-logo demo">
                                <img style="max-width: 180px;" src="@/assets/logo.png">
                            </span>
                        </a>
                    </div>
                    <!-- /Logo -->
                    <h4 class="mb-2">Zapomniałeś hasła? </h4>
                    <p class="mb-4">Wprowadź swój email, aby wygenerować link z przypomnieniem.</p>

                    <form @submit.prevent="doLogin" id="formAuthentication" class="mb-3">
                        <div class="mb-3">
                            <label for="email" class="form-label">Adres email</label>
                            <input v-model="email" type="text" class="form-control" id="email" name="email-username"
                                placeholder="Twój adres e-mail" autofocus />
                        </div>

                        <button class="btn btn-primary d-grid w-100">Wyślij link resetujący hasło</button>
                    </form>

                </div>
            </div>
            <!-- /Login -->
        </div>
    </div>
</template>

<script>
import '../assets/page-auth.css'
// import axios from 'axios'
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data: function () {
        return {
            email: '',
            password: '',
            validation: false,
            passwordShow: false
        }
    },
    methods: {
        doLogin: function () {
            // this.validation = false;
            // axios.post(process.env.VUE_APP_BASE_URL + '/auth/token', { email: this.email, password: this.password, device_name: 'device' }).then(response => {
            //     console.log(response);
            //     localStorage.setItem('algorytm_token', response.data.token);
            //     this.$store.commit('access_token', localStorage.getItem('algorytm_token'));
            //     this.$emit('login', true);
            // }, () => {
            //     this.validation = true;
            // })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
