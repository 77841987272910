<template>
    <div class="modal fade show" :id="id" tabindex="-1" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-simple" :class="class_other">
            <div v-if="modal" class="modal-content p-3 p-md-5">
                <div class="modal-body">
                    <h3 v-if="title" v-html="title"> </h3>
                    <button type="button" class="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
                    <slot class="text-center mb-4"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
    props: ['value', 'title', 'class_other'],
    data: function () {
        return {
            id: 'modal' + Math.round(Math.random() * 100000),
            modal: null,
        }
    },
    mounted() {
        document.body.append(document.getElementById(this.id));
        this.modal = new Modal(document.getElementById(this.id))
        let vm = this;
        document.getElementById(this.id).addEventListener('hidden.bs.modal', function () {
            vm.triggerModal(false);
        })
        this.triggerModal(this.value, false);
    },
    methods: {
        triggerModal: function (value, emit = true) {
            if (value == true) {
                this.modal.show();
            } else {
                this.modal.hide()
                if (emit) {
                    this.$emit('hide');
                }
            }
            this.$emit('input', value);
        }
    },
    watch: {
        value: function () {
            this.triggerModal(this.value);
        }
    },
    onDestroy: function () {
        document.getElementById(this.id).remove();
    }
}
</script>
<style>
.modal-xxl {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 10px !important;
}
.modal-xlg {
    width: 75% !important;
    max-width: 75% !important;
    padding: 0 10px !important;
}
</style>