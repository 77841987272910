<template>
    <div :style="'width:' + (size?size:40) + 'px; height:'+(size?size:40)+'px; font-size:'+(size?parseInt(size*0.375):15)+'px'" class="avatar">
        
        <img :style="'width:' + (size?size:40) + 'px; max-height:'+(size?size:40)+'px; object-fit:contain'" v-if="getUserImage()" :src="getUserImage()" alt
            class="h-auto rounded-circle" :title="getInitials()"/>
        <span v-else class="avatar-initial rounded-circle " :class="getColor()">{{ getInitials() }}</span>
    </div>
</template>
<script>
export default {
    name: 'MagicoAvatar',
    props:['user_id','size'],
    methods: {
        getUserImage() {
            return this.$store.state.users.find(item => this.user_id == item.id)?.avatar;
        },
        getColor: function(){
            return ['bg-label-success','bg-label-info','bg-label-primary','bg-label-danger','bg-label-secondary'][parseInt(this.user_id)%5]
        },
        getInitials: function () {

            let user = this.$store.state.users.find(item => this.user_id == item.id)
            let name = user?.name ? user?.name : user?.username
            var nameSplit = String(name).toUpperCase().split(' '), initials;
            if (nameSplit.length == 1) {
                initials = nameSplit[0] ? nameSplit[0].slice(0,2) : '?';
            } else {
                initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
            }
            return initials;

        }
    }
}
</script>
<style>


</style>