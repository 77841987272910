<template>
  <div class="container-xxl flex-grow-1 container-p-y">
    <div class="app-chat overflow-hidden shadow-sm rounded-1">
      <div class="row g-0">
        <!-- Sidebar Left -->
        <div class="col app-chat-sidebar-left app-sidebar overflow-hidden " id="app-chat-sidebar-left">
          <div
            class="chat-sidebar-left-user sidebar-header d-flex flex-column justify-content-center align-items-center flex-wrap p-4 mt-2">
            <MagicoAvatar :user_id="$store.state.user?.id"></MagicoAvatar>
            <h5 class="mt-3 mb-1">John Doe</h5>
            <small class="text-muted">UI/UX Designer</small>
            <i class="bx bx-x bx-sm cursor-pointer close-sidebar me-1 fs-4" @click="sidebarShow = !sidebarShow"
              data-bs-toggle="sidebar" data-overlay="" data-target="#app-chat-sidebar-left"></i>
          </div>
          <div class="sidebar-body px-4 pb-4 ps ps--active-y">
            <div class="my-3">
              <span class="text-muted text-uppercase">About</span>
              <textarea id="chat-sidebar-left-user-about" class="form-control chat-sidebar-left-user-about mt-2" rows="4"
                maxlength="120">Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.</textarea>
            </div>
            <div class="my-4">
              <span class="text-muted text-uppercase">Status</span>
              <div class="d-grid gap-1 mt-2">
                <div class="form-check form-check-success">
                  <input name="chat-user-status" class="form-check-input" type="radio" value="active" id="user-active"
                    checked="">
                  <label class="form-check-label" for="user-active">Active</label>
                </div>
                <div class="form-check form-check-danger">
                  <input name="chat-user-status" class="form-check-input" type="radio" value="busy" id="user-busy">
                  <label class="form-check-label" for="user-busy">Busy</label>
                </div>
                <div class="form-check form-check-warning">
                  <input name="chat-user-status" class="form-check-input" type="radio" value="away" id="user-away">
                  <label class="form-check-label" for="user-away">Away</label>
                </div>
                <div class="form-check form-check-secondary">
                  <input name="chat-user-status" class="form-check-input" type="radio" value="offline" id="user-offline">
                  <label class="form-check-label" for="user-offline">Offline</label>
                </div>
              </div>
            </div>
            <div class="my-4">
              <span class="text-muted text-uppercase">Settings</span>
              <ul class="list-unstyled d-grid gap-2 mt-2">
                <li class="d-flex justify-content-between align-items-center">
                  <div>
                    <i class="bx bx-check-circle me-1"></i>
                    <span class="align-middle">Two-step Verification</span>
                  </div>
                  <div class="form-check form-switch mb-0">
                    <input class="form-check-input" type="checkbox" id="twoStepVerification">
                  </div>
                </li>
                <li class="d-flex justify-content-between align-items-center">
                  <div>
                    <i class="bx bx-bell me-1"></i>
                    <span class="align-middle">Notification</span>
                  </div>
                  <div class="form-check form-switch mb-0">
                    <input class="form-check-input" type="checkbox" id="switchNotification" checked="">
                  </div>
                </li>
                <li>
                  <i class="bx bx-user me-1"></i>
                  <span class="align-middle">Invite Friends</span>
                </li>
                <li>
                  <i class="bx bx-trash-alt me-1"></i>
                  <span class="align-middle">Delete Account</span>
                </li>
              </ul>
            </div>
            <div class="d-flex mt-4">
              <button class="btn btn-primary" data-bs-toggle="sidebar" data-overlay=""
                data-target="#app-chat-sidebar-left">
                Logout
              </button>
            </div>
            <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
              <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; height: 326px; right: 0px;">
              <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 183px;"></div>
            </div>
          </div>
        </div>
        <!-- /Sidebar Left-->

        <!-- Chat & Contacts -->
        <div :style="sidebarShow ? 'opacity:1; left:0' : ''"
          class="col app-chat-contacts app-sidebar flex-grow-0 overflow-hidden border-end bg-light"
          id="app-chat-contacts">
          <div class="sidebar-header pt-3 px-3 mx-1">
            <div class="d-flex align-items-center me-3 me-lg-0">
              <div class="flex-shrink-0 avatar avatar-online me-2" data-bs-toggle="sidebar" data-overlay="app-overlay-ex"
                data-target="#app-chat-sidebar-left">
                <MagicoAvatar :user_id="$store.state.user?.id"></MagicoAvatar>
              </div>
              <div class="flex-grow-1 input-group input-group-merge rounded-pill ms-1">
                <span class="input-group-text" id="basic-addon-search31"><i class="bx bx-search fs-4"></i></span>
                <input type="text" v-model="searchRoom" class="form-control chat-search-input" placeholder="Szukaj..."
                  aria-label="Szukaj..." aria-describedby="basic-addon-search31">
              </div>
            </div>
            <i class="bx bx-x cursor-pointer position-absolute top-0 end-0 mt-2 me-1 fs-4 d-lg-none d-block"
              data-overlay="" @click="sidebarShow = !sidebarShow" data-bs-toggle="sidebar"
              data-target="#app-chat-contacts"></i>
          </div>
          <hr class="container-m-nx mt-3 mb-0">
          <div class="sidebar-body ps ps--active-y">
            <!-- Chats -->
            <ul class="list-unstyled chat-contact-list pt-1" id="chat-list">
              <li class="chat-contact-list-item chat-contact-list-item-title">
                <h5 class="text-primary mb-0">Projekty</h5>
              </li>
              <li class="chat-contact-list-item chat-list-item-0 d-none">
                <h6 class="text-muted mb-0">No Chats Found</h6>
              </li>
              <li v-for="room, index in chatroomsFilteredList"
                :class="{ 'active': room.chat_room_id == currentRoom.chat_room_id }" :key="index"
                class="chat-contact-list-item" @click="clickRoom(room)">
                <a class="d-flex align-items-center">
                  <div class="flex-shrink-0 avatar avatar-busy">
                    <span class="avatar-initial rounded-circle bg-label-success">{{ getInitials(room.room_name) }}</span>
                  </div>
                  <div class="chat-contact-info flex-grow-1 ms-3">
                    <h6 class="chat-contact-name text-truncate m-0">{{ room.room_name }}</h6>
                  </div>
                  <!-- <small class="text-muted mb-auto">1 Day</small> -->
                </a>
              </li>
            </ul>

            <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
              <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; height: 441px; right: 0px;">
              <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 181px;"></div>
            </div>
          </div>
        </div>
        <!-- /Chat contacts -->

        <!-- Chat History -->
        <div class="col app-chat-history ">
          <div class="chat-history-wrapper">
            <div class="chat-history-header border-bottom bg-light">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex overflow-hidden align-items-center">
                  <i class="bx bx-menu bx-sm cursor-pointer d-lg-none d-block me-2" @click="sidebarShow = !sidebarShow"
                    data-bs-toggle="sidebar" data-overlay="" data-target="#app-chat-contacts"></i>
                  <div class="flex-shrink-0 avatar avatar-busy">
                    <span class="avatar-initial rounded-circle bg-label-success">{{ getInitials(currentRoom.room_name)
                    }}</span>
                  </div>
                  <div class="chat-contact-info flex-grow-1 ms-3">
                    <h6 class="m-0">{{ currentRoom.room_name }} </h6>
                    <small class="user-status text-muted"></small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <!-- <i class="bx bx-phone-call cursor-pointer d-sm-block d-none me-3 fs-4"></i>
                  <i class="bx bx-video cursor-pointer d-sm-block d-none me-3 fs-4"></i>
                  <i class="bx bx-search cursor-pointer d-sm-block d-none me-3 fs-4"></i> -->
                  <div class="dropdown">
                    <i class="bx bx-dots-vertical-rounded cursor-pointer fs-4" id="chat-header-actions"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </i>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="chat-header-actions">
                      <a class="dropdown-item" target="_blank"
                        :href="'https://algorytm.serwer.magico.pl/publicchat/#/' + currentRoom.room_public_link">Udostępnij
                        link</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-history-body ps ps--active-y" style="overflow: auto;" ref='messageDisplay'>
              <ul class="list-unstyled chat-history mb-0">
                <li v-for="message, index in messages" :key="index" class="chat-message"
                  :class="{ 'chat-message-right': message.user_id == $store.state.user.id }">
                  <!--left -->
                  <div v-if="message.user_id != $store.state.user.id" class="d-flex overflow-hidden">
                    <div class="user-avatar flex-shrink-0 me-3">
                      <div class="avatar avatar-sm" v-if="message.user_id == 0">
                        <img src="@/assets/icons/client.png" alt="Avatar" class="rounded-circle">
                      </div>
                      <MagicoAvatar v-else :user_id="message.user_id" :size="32"></MagicoAvatar>

                    </div>
                    <div class="chat-message-wrapper flex-grow-1">
                      <div class="chat-message-text pt-1">
                        <small v-if="message.user_id == 0" class="fw-bolder"><i class="bi bi-share me-1"></i> {{
                          message.user_name }}
                        </small>
                        <p class="mb-0">{{ message.chat_message }}.</p>
                      </div>
                      <div class="text-muted mt-1">
                        <small>{{ message.created_at }}</small>
                      </div>
                    </div>
                    <small v-if="message.user_id != 0" class="username"> {{
                      getUsername(message.user_id) }} </small>

                  </div>
                  <!--right -->
                  <div v-if="message.user_id == $store.state.user.id" class="d-flex overflow-hidden">
                    <div class="chat-message-wrapper flex-grow-1">
                      <div class="chat-message-text">
                        <p class="mb-0">{{ message.chat_message }}</p>
                      </div>
                      <div class="text-end text-muted mt-1">
                        <i class="bx bx-check-double text-success"></i>
                        <small>{{ message.created_at }}</small>
                      </div>
                    </div>
                    <div class="user-avatar flex-shrink-0 ms-3 text-dark">
                      <div class="avatar avatar-sm" v-if="message.user_id == 0">
                        <img src="@/assets/icons/client.png" alt="Avatar" class="rounded-circle">
                      </div>
                      <MagicoAvatar v-else :user_id="message.user_id" :size="35"></MagicoAvatar>
                    </div>
                  </div>
                </li>


              </ul>
              <!-- <div class="ps__rail-x" style="left: 0px; bottom: -285px;">
                <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
              </div>
              <div class="ps__rail-y" style="top: 285px; height: 356px; right: 0px;">
                <div class="ps__thumb-y" tabindex="0" style="top: 94px; height: 117px;"></div>
              </div> -->
            </div>
            <!-- Chat message form -->
            <div class="chat-history-footer bg-light position-relative">
              <div v-if="!scroolOn && newMessages" class="alert bg-success w-100 text-center text-dark"
                style="position: absolute; top:-80px; right:0;left:0">
                Nowe wiadomości
              </div>
              <form class="form-send-message d-flex justify-content-between align-items-center">
                <input v-model="messageText" class="form-control message-input border-0 me-3 shadow-none"
                  placeholder="Wpisz tu swoją wiadomość...">
                <div class="message-actions d-flex align-items-center">
                  <!-- <i class="speech-to-text bx bx-microphone bx-sm cursor-pointer"></i>
                  <label for="attach-doc" class="form-label mb-0">
                    <i class="bx bx-paperclip bx-sm cursor-pointer mx-3"></i>
                    <input type="file" id="attach-doc" hidden="">
                  </label> -->
                  <button @click.prevent="sendMessage" class="btn btn-primary d-flex send-msg-btn">
                    <i class="bx bx-paper-plane me-md-1 me-0"></i>
                    <span class="align-middle d-md-inline-block d-none">Wyślij</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Chat History -->



        <div class="app-overlay"></div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/chat.css'
import axios from '../../api/axios';
import MagicoAvatar from '../../components/MagicoAvatar.vue';


export default {
  components: {
    MagicoAvatar
  },
  created: function () {
    var vm = this;
    vm.intervalId = setInterval(function () { vm.getMessages() }, 5000);
    this.getRooms();
  },
  onDestroy: function () {
    clearInterval(this.intervalId);
  },
  mounted: function () {
    this.messageDisplay = this.$refs.messageDisplay;
    this.getMessages();
    let vm = this;
    this.messageDisplay.addEventListener('scroll', function () {
      console.log(vm.messageDisplay.scrollHeight - vm.messageDisplay.scrollTop - vm.messageDisplay.clientHeight);
      if (vm.messageDisplay.scrollHeight - vm.messageDisplay.scrollTop - vm.messageDisplay.clientHeight > 100) {
        vm.scroolOn = false;
      } else {
        vm.scroolOn = true;
      }
    })
  },
  data: function () {
    return {
      messages: [],
      rooms: [],
      searchRoom: '',
      currentRoom: { 'chat_room_id': 1, 'room_name': 'Pierwszy' },
      messageText: '',
      messageDiv: '',
      scroolOn: true,
      newMessages: false,
      lastMessageId: 0,
      intervalId: 0,
      sidebarShow: false,
    }
  },
  computed: {

    chatroomsFilteredList() {
      return this.rooms.filter((rooms) => {
        return this.searchRoom.toLowerCase().split(' ').every(v => rooms.room_name.toLowerCase().includes(v));
      });
    },
  },
  methods: {
    clickRoom(room) {
      this.currentRoom = room;
      this.getMessages();
    },
    getRooms() {
      axios.get('api/v1/chat/room').then(response => {
        console.log(response);
        this.rooms = response.data.data;
        this.currentRoom = this.rooms[0];
      })
    },
    getUserImage(user_id) {
      return this.$store.state.users.find(item => user_id == item.id)?.avatar;
    },
    getUsername(user_id) {
      return this.$store.state.users.find(item => user_id == item.id)?.name;
    },
    sendMessage() {
      if (this.messageText.replace(/ /g, '') == '') return;
      this.scroolOn = true;
      axios.post('api/v1/chat/' + this.currentRoom.chat_room_id + '/message', {
        chat_message: this.messageText,
        chat_room_id: this.currentRoom.chat_room_id,
        user_id: 1,
      }).then(() => {
        this.getMessages()
      });
      this.messageText = '';
    },
    getMessages() {
      var vm = this;
      axios.get('api/v1/chat/' + this.currentRoom.chat_room_id + '/message').then(response => {
        this.messages = response.data.data;
        if (this.lastMessageId != this.messages[this.messages.length - 1].chat_message_id) {
          this.newMessages = true;
          this.lastMessageId = this.messages[this.messages.length - 1].chat_message_id
        }
        if (vm.scroolOn) {
          this.messageDisplay.scrollTop = this.messageDisplay.scrollHeight;
        }
        this.$nextTick(function () {
          if (vm.scroolOn) {
            this.newMessages = false;
            vm.messageDisplay.scrollTop = vm.messageDisplay.scrollHeight;
          }
        })
      })
    },
    getInitials: function (name) {

      name = name || '';
      var nameSplit = String(name).toUpperCase().split(' '), initials;
      if (nameSplit.length == 1) {
        initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
      } else {
        initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
      }
      return initials;

    }
  }
}
</script>
<style>
.chat-message-right {
  color: white
}

.chat-message:not(.chat-message-right) .chat-message-text {
  background-color: white;
}

.chat-message .username {
  display: none;
}

.chat-message:hover .username {
  display: inline-block;
  color: gray;
  margin-left: 5px;
  margin-top: 5px;
}
</style>