<template>
    <li class="nav-item navbar-dropdown dropdown-user dropdown">
        <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
          <MagicoAvatar :user_id="$store.state.user.id"></MagicoAvatar>
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
            <li>
                <a class="dropdown-item" href="#/user/me">
                    <div class="d-flex">
                        <div class="flex-shrink-0 me-3">
                            <MagicoAvatar :user_id="$store.state.user.id"></MagicoAvatar>
                        </div>
                        <div class="flex-grow-1">
                            <span class="fw-semibold d-block">{{ $store.state.user.name }}</span>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <div class="dropdown-divider"></div>
            </li>
            <li class="">
                <a to="/user/me" class="dropdown-item d-flex align-items-center" href="#" target="_blank">
                    <i class="bx bx-user me-2"></i>
                    <span class="align-middle">Mój profil</span>
                </a>
            </li>


            <li>
                <div class="dropdown-divider"></div>
            </li>
            <li class="">
                <a @click.prevent="logout" to="/logout" class="dropdown-item d-flex align-items-center" href="#"
                    target="_blank">
                    <i class="bx bx-power-off me-2"></i>
                    <span class="align-middle">Wyloguj</span>
                </a>
            </li>
        </ul>
    </li>
</template>
<script>
import MagicoAvatar from '../MagicoAvatar.vue';

export default {
    methods: {
        logout: function () {
            localStorage.removeItem("algorytm_token");
            window.location.reload();
        },
    },
    components: { MagicoAvatar }
}
</script>