import axios from 'axios';
const myaxios = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

myaxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
myaxios.defaults.timeout = 10000;

myaxios.interceptors.request.use(
    (config) => {
        const token = localStorage.algorytm_token;
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

myaxios.interceptors.response.use((response) => {
    return response
}, function (error) {
    // const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('algorytm_token');
        window.location.reload();
        return Promise.reject(error);
    }
    // if (error.response && error.response.status === 401 && !originalRequest._retry) {
    //     originalRequest._retry = true;
    //     if (!localStorage.client_id || !localStorage.client_secret) {
    //         console.warn('try to get access token with no credentials')
    //         return Promise.reject(error);
    //     }
    //     return authAxios.getToken().then(res => {
    //         if (res.status === 200) {
    //             localStorage.setItem('access_token', res.data.access_token)
    //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
    //             return axios(originalRequest);
    //         }
    //     })
    // }
    return Promise.reject(error);
});

export default myaxios;